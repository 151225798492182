import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { ChildrenOutletContexts, NavigationEnd, Router } from "@angular/router";
import { SeasonsActions } from "@api/seasons/state/seasons.actions";
import { Store } from "@ngrx/store";
import { NzMessageService } from "ng-zorro-antd/message";
import { filter, map, startWith, switchMap, take, tap } from "rxjs/operators";
import { User } from "src/app/api/users/model";
import { UsersApiActions } from "src/app/api/users/state/users.actions";
import { UsersService } from "src/app/api/users/users.service";
import { environment } from "src/environments/environment";
import { AuthService } from "../../services/auth/auth.service";

export const slideInAnimation = trigger("slideInAnimation", [
  transition("* <=> *, void <=> *, * <=> void", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ left: "-100%" })], { optional: true }),
    query(":leave", animateChild()),
    group([
      query(":leave", [animate("300ms ease-out", style({ left: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("300ms ease-out", style({ left: "0%" }))], {
        optional: true,
      }),
    ]),
    query(":enter", animateChild(), { optional: true }),
  ]),
]);

export const fadeAnimation = trigger("fadeAnimation", [
  transition("* <=> *, void <=> *, * <=> void", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          width: "100%",
          top: "100%",
          bottom: 0,
          "min-height": "100vh",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ opacity: 0 })], { optional: true }),
    query(":leave", animateChild(), { optional: true }),
    group([
      query(":leave", [animate("500ms ease-out", style({ opacity: 0 }))], {
        optional: true,
      }),
      query(":enter", [animate("500ms ease-out", style({ opacity: 1 }))], {
        optional: true,
      }),
    ]),
    query(":enter", animateChild(), { optional: true }),
  ]),
]);

@Component({
  selector: "pescp-main-page-layout",
  templateUrl: "./main-page-layout.component.html",
  styleUrls: ["./main-page-layout.component.scss"],
  animations: [slideInAnimation, fadeAnimation],
})
export class MainPageLayoutComponent implements OnInit {
  constructor(
    private readonly message: NzMessageService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly usersService: UsersService,
    private readonly store: Store,
    private readonly contexts: ChildrenOutletContexts
  ) {}

  readonly currentRoute$ = this.router.events.pipe(
    startWith(new NavigationEnd(0, "/", this.router.url)),
    filter((event) => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.urlAfterRedirects === "/")
  );
  readonly user$ = this.authService.user$.pipe(filter((user) => !!user));
  readonly hasRegulationsAccepted$ = this.user$.pipe(
    startWith(null),
    map((user: User) => (user ? user.regulationaccept : true))
  );

  appEnabled: boolean = null;

  ngOnInit() {
    this.handlePassCode();

    this.store.dispatch(
      SeasonsActions.seasonFetchById({
        seasonId: 50,
      })
    );
    this.store.dispatch(
      SeasonsActions.seasonFetchById({
        seasonId: 51,
      })
    );
    this.store.dispatch(
      SeasonsActions.seasonFetchById({
        seasonId: 52,
      })
    );
    this.store.dispatch(SeasonsActions.seasonsFetch({}));
  }

  handleCancel() {
    this.message.error("Brak akceptacji regulaminów!");
    this.authService.logout();
  }

  handleOk() {
    this.user$
      .pipe(
        take(1),
        filter((user) => !!user),
        map((user: User) => {
          const result: User = {
            ...user,
            privacyaccept: true,
            regulationaccept: true,
          };
          return result;
        }),
        switchMap((user) => this.usersService.updateUser(user)),
        tap((user) =>
          this.store.dispatch(UsersApiActions.userUpdateByIDSuccess({ user }))
        )
      )
      .subscribe();
  }

  prepareRoute() {
    return this.contexts.getContext("primary")?.route?.snapshot.component.name;
  }

  private handlePassCode() {
    if (environment.passCode.length > 0) {
      const passCode = prompt("Wprowadź hasło");
      if (passCode === environment.passCode) {
        this.appEnabled = true;
      } else {
        this.appEnabled = false;
      }
    } else {
      this.appEnabled = true;
    }
  }
}
