import { HttpClient, HttpRequest, HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { ApiService } from "src/app/modules/core/services/api/api.service";

@Component({
  selector: "app-team-create",
  templateUrl: "./team-create.component.html",
  styleUrls: ["./team-create.component.scss"],
})
export class TeamCreateComponent implements OnInit {
  validateForm: UntypedFormGroup;
  selectedFile: File;

  buttonLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private message: NzMessageService,
    private router: Router,
    private http: HttpClient
  ) {}

  submitForm(): void {
    this.buttonLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      const fd = new FormData();
      const blob = new Blob(
        [JSON.stringify({ name: this.validateForm.get("name").value })],
        { type: "application/json" }
      );
      fd.append("body", blob);
      fd.append("image", this.selectedFile);

      const req = new HttpRequest("POST", this.api.backendUrl + "team", fd, {
        headers: this.api.addDefaultHeaders(),
        reportProgress: true,
      });

      this.http.request(req).subscribe(
        (response) => {
          if (response instanceof HttpResponse && response.status === 200) {
            this.message.success("Zespół został dodany!");
            this.buttonLoading = false;
            this.router.navigate(["/team", response.body]);
          }
        },
        (error) => {
          console.error(error.error);
          this.message.error(error.error);
          this.buttonLoading = false;
        }
      );
    } else {
      this.buttonLoading = false;
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      image: [null],
    });
  }

  selectFile(e) {
    this.selectedFile = e.target.files.item(0);
  }
}
