import { Component, OnInit, isDevMode } from "@angular/core";
import { Store } from "@ngrx/store";
import { NzMessageService } from "ng-zorro-antd/message";
import { filter, map, startWith, tap, withLatestFrom } from "rxjs/operators";
import { User } from "src/app/api/users/model";
import { UsersApiActions } from "src/app/api/users/state/users.actions";
import { ApiService } from "src/app/modules/core/services/api/api.service";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { TeamMember } from "../../models/team-member";
import { TeamView } from "../../models/team-view";
import { TeamMembershipStatusService } from "../../services/teamMembershipStatus/team-membership-status.service";

@Component({
  selector: "app-teams-list",
  templateUrl: "./teams-list.component.html",
  styleUrls: ["./teams-list.component.scss"],
})
export class TeamsListComponent implements OnInit {
  constructor(
    private api: ApiService,
    private teamMembership: TeamMembershipStatusService,
    private message: NzMessageService,
    private readonly authService: AuthService,
    private readonly store: Store
  ) {}

  loading = true;
  teams: TeamView[];

  readonly ownedTeams$ = this.authService.user$.pipe(
    startWith(null),
    filter((user) => !!user),
    map((user: User) => user.ownedTeams)
  );
  readonly belongingTeams$ = this.authService.user$.pipe(
    startWith(null),
    filter((user) => !!user),
    map((user: User) =>
      user.belongingTeams.filter((x) => x.proposalStatus === 1)
    )
  );

  readonly isAuthenticated$ = this.authService.isAuthenticated$;

  ngOnInit() {
    this.reloadList();
  }

  reloadList() {
    this.loading = true;
    this.api
      .get<TeamView[]>("team")
      .toPromise()
      .then((data) => {
        data.map((x) => {
          x.createdTime = new Date(x.createdTime);
          x.members.map((y) => {
            if (y.dateStart != null) {
              y.dateStart = new Date(y.dateStart);
            }
            if (y.dateStop != null) {
              y.dateStop = new Date(y.dateStop);
            }
          });
        });
        this.teams = data;
        if (isDevMode()) {
          console.log(this.teams);
        }
        this.loading = false;
      });
  }

  resolveStatus(status: number): string {
    return this.teamMembership.getStatus(status);
  }

  acceptProposal(memberid: number, teamid: number) {
    const member: TeamMember = {} as TeamMember;
    member.id = memberid;
    member.proposalStatus = 1;
    member.teamid = teamid;
    this.api
      .put<TeamMember>("team/update-member", member)
      .pipe(
        withLatestFrom(this.authService.user$),
        tap(([member, user]: [TeamMember, User]) => {
          const result: User = { ...user };
          result.belongingTeams.find(
            (x) => x.id === memberid
          ).proposalStatus = 1;
          this.store.dispatch(
            UsersApiActions.userUpdateByIDSuccess({ user: { ...result } })
          );
          this.message.success("Dołączono do zespołu.");
          this.reloadList();
        })
      )
      .subscribe();
  }

  declineProposal(memberid: number, teamid: number) {
    const member: TeamMember = {} as TeamMember;
    member.id = memberid;
    member.teamid = teamid;
    member.proposalStatus = 2;
    this.api
      .put<TeamMember>("team/update-member", member)
      .pipe(
        withLatestFrom(this.authService.user$),
        tap(([member, user]: [TeamMember, User]) => {
          const result: User = { ...user };
          result.belongingTeams.find(
            (x) => x.id === memberid
          ).proposalStatus = 1;
          this.store.dispatch(
            UsersApiActions.userUpdateByIDSuccess({ user: { ...result } })
          );
          this.message.info("Odrzucono propozycję dołączenia");
          this.reloadList();
        })
      )
      .subscribe();
  }

  leaveTeam(memberid: number, teamid: number) {
    const member: TeamMember = {} as TeamMember;
    member.id = memberid;
    member.teamid = teamid;
    member.proposalStatus = 3;
    this.api
      .put<TeamMember>("team/update-member", member)
      .pipe(
        withLatestFrom(this.authService.user$),
        tap(([member, user]: [TeamMember, User]) => {
          const result: User = { ...user };
          result.belongingTeams.find(
            (x) => x.id === memberid
          ).proposalStatus = 1;
          this.message.info("Opuszczono zespół");
          this.reloadList();
        })
      )
      .subscribe();
  }
}
