import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EventSessionView } from "@api/seasons/model";
import { selectSeasonById } from "@api/seasons/state/seasons.selectors";
import { Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Track } from "../../models/track";

@Component({
  selector: "app-track",
  templateUrl: "./track.component.html",
  styleUrls: ["./track.component.scss"],
})
export class TrackComponent implements OnInit {
  track: Track;
  event: EventSessionView;
  showMobileTrackContent = false;

  staticUrl: string = environment.staticUrl;

  constructor(private route: ActivatedRoute, private store: Store) {}

  ngOnInit() {
    const trackID = Number.parseInt(this.route.snapshot.paramMap.get("id"), 10);

    combineLatest([
      this.store.select(selectSeasonById(50)).pipe(
        filter(Boolean),
        map((season) => season.events)
      ),
      this.store.select(selectSeasonById(52)).pipe(
        filter(Boolean),
        map((season) => season.events)
      ),
    ])
      .pipe(
        map(([playOffs, season]) => [
          ...structuredClone(playOffs),
          ...structuredClone(season),
        ]),
        map((events) => events.find((x) => x.track.id === trackID)),
        catchError(() => of(null))
      )
      .subscribe((data) => {
        this.event = data;
        this.track = data.track;
      });
  }

  getTrackBackground(val) {
    return "url(" + this.staticUrl + "tracks/" + val + ")";
  }

  @HostListener("window:resize", ["$event"])
  resized(e) {
    if (document.body.clientWidth <= 960) {
      this.showMobileTrackContent = true;
    } else {
      this.showMobileTrackContent = false;
    }
  }
}
