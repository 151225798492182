<section class="team-create-bg">
    <div class="app-layout">
        <div class="team-create">
            <h1 class="section-heading">Stwórz nowy zespół</h1>
            <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
                <nz-form-item>
                    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">Nazwa zespołu</nz-form-label>
                    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Nazwa zespołu jest obowiązkowa!">
                        <input type="text" nz-input formControlName="name" placeholder="Nazwa" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="name">Logotyp zespołu</nz-form-label>
                    <nz-form-control [nzSm]="14" [nzXs]="24">
                        <input type="file" formControlName="image" (change)="selectFile($event)" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <div nz-col [nzSm]="6" [nzXs]="24"></div>
                    <nz-form-control [nzSm]="14" [nzXs]="24">
                        <button nz-button [nzLoading]="buttonLoading" class="login-form-button btn btn-red">Zapisz
                            zmiany</button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
    </div>
</section>