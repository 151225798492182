<app-loader *ngIf="!event"></app-loader>
<section *ngIf="event" [ngStyle]="{ 'background-image': getTrackBackground(track.trackimage) }" class="track-bg">
  <div class="track-background">
    <div class="app-layout">
      <div class="track-buttons">
        <a class="back-button btn btn-outlined-white" [routerLink]="'/calendar'">Powrót</a>
      </div>
      <ng-template #desktopTrackContent>
        <div class="content">
          <div class="track-info">
            <div class="track-name">
              <h1 class="section-heading red">{{ track.name }}</h1>
              <div class="sep-bor"></div>
            </div>
            <div class="event-date">
              <p>{{ event.sessions[0].dateStart | date : "dd.MM.yyyy" }}</p>
              <p>{{ event.sessions[0].dateStart | date : "HH:mm" }}</p>
            </div>
            <div class="track-description">
              <div class="track-description-heading">
                <h1 class="section-heading red">Opis toru</h1>
              </div>
              <p [innerHTML]="track.trackdescription"></p>
            </div>
          </div>
          <div class="track-image">
            <img alt="Mapka toru" [src]="
                staticUrl + 'trackmaps/porscheesports-2024/' + track.trackmap
              " />
          </div>
        </div>
      </ng-template>
      <div *ngIf="showMobileTrackContent; else desktopTrackContent" class="content">
        <div class="track-info">
          <div class="track-name">
            <h1 class="section-heading">{{ track.name }}</h1>
          </div>
          <div class="event-date">
            <p>{{ event.sessions[0].dateStart | date : "dd.MM.yyyy" }}</p>
            <p>{{ event.sessions[0].dateStart | date : "HH:mm" }}</p>
          </div>
          <div class="track-description">
            <div class="track-description-heading">
              <h1 class="section-heading red">Opis toru</h1>
            </div>
            <p [innerHTML]="track.trackdescription"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>