import { Component, OnInit, isDevMode } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzUploadChangeParam } from "ng-zorro-antd/upload";
import { filter, map, tap } from "rxjs/operators";
import { UserMinimal } from "src/app/api/users/model";
import { UsersService } from "src/app/api/users/users.service";
import { ApiService } from "src/app/modules/core/services/api/api.service";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { environment } from "src/environments/environment";
import { TeamMember } from "../../models/team-member";
import { TeamMemberView } from "../../models/team-member-view";
import { TeamView } from "../../models/team-view";
import { TeamMembershipStatusService } from "../../services/teamMembershipStatus/team-membership-status.service";

@Component({
  selector: "app-team-detailed",
  templateUrl: "./team-detailed.component.html",
  styleUrls: ["./team-detailed.component.scss"],
})
export class TeamDetailedComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private teamMembership: TeamMembershipStatusService,
    private fb: UntypedFormBuilder,
    private message: NzMessageService,

    private readonly authService: AuthService,
    private readonly usersService: UsersService
  ) {}

  loading = true;
  teamid: number;
  addDriverModalVisible = false;
  teamLimit = false;
  userListLoading = true;
  userList: UserMinimal[];
  team: TeamView;
  addDriverForm: UntypedFormGroup;
  lastupdateTimestamp = null;

  staticUrl: string = environment.staticUrl;

  readonly userId$ = this.authService.user$.pipe(
    filter((user) => !!user),
    map((user) => user.id)
  );
  readonly userToken$ = this.authService.getToken();

  ngOnInit() {
    this.teamid = Number.parseInt(this.route.snapshot.paramMap.get("id"), 10);
    this.lastupdateTimestamp = new Date().getTime();
    this.loadTeam();
    this.usersService
      .getUsers()
      .toPromise()
      .then((u) => {
        this.userList = u;
        this.userListLoading = false;
      });
    this.buildForms();
  }

  loadTeam() {
    this.api
      .get<TeamView>(["team", this.teamid].join("/"))
      .pipe(
        map((team) => {
          team.createdTime = new Date(team.createdTime);
          team.members.map((x) => {
            x.dateStart = new Date(x.dateStart);
            if (x.dateStop != null) {
              x.dateStop = new Date(x.dateStop);
            }
          });
          return team;
        }),
        tap(() => (this.loading = false))
      )
      .subscribe((team) => {
        this.team = team;
      });
  }

  buildForms() {
    this.addDriverForm = this.fb.group({
      userid: [null, Validators.required],
    });
  }

  getOnlyActiveMembers(): TeamMemberView[] {
    return this.team.members.filter((x) => {
      return x.proposalStatus === 1;
    });
  }

  getMembersWithoutKicked(): TeamMemberView[] {
    return this.team.members.filter((x) => {
      return x.proposalStatus < 2;
    });
  }

  resolveStatus(status: number): string {
    return this.teamMembership.getStatus(status);
  }

  disbandMember(memberid: number, teamid: number) {
    if (isDevMode()) {
      console.log(memberid, teamid);
    }
    const member: TeamMember = {} as TeamMember;
    member.id = memberid;
    member.teamid = teamid;
    member.proposalStatus = 4;
    this.api
      .put<TeamMember>("team/update-member", member)
      .toPromise()
      .then((response) => {
        this.loadTeam();
      });
  }

  addNewDriver() {
    const drivers = this.getMembersWithoutKicked();
    if (drivers.length < 2) {
      this.addDriverModalVisible = true;
    } else {
      this.message.error("Maksymalna liczba zawodników w zespole wynosi 2!");
    }
  }

  handleCancelInvite() {
    this.addDriverModalVisible = false;
  }

  handleOkInvite(e: Event) {
    e.preventDefault();
    for (const i in this.addDriverForm.controls) {
      this.addDriverForm.controls[i].markAsDirty();
      this.addDriverForm.controls[i].updateValueAndValidity();
    }
    if (this.addDriverForm.valid) {
      const member: TeamMember = {} as TeamMember;
      member.teamid = this.teamid;
      member.userid = this.addDriverForm.get("userid").value;
      this.api
        .post<TeamMember>("team/add-member", member)
        .toPromise()
        .then((obj) => {
          this.loading = true;
          this.loadTeam();
          this.addDriverModalVisible = false;
        });
    }
  }

  getUserAvatar(selected: string) {
    if (selected == null) {
      return null;
    }
    const u = this.userList.find((x) => x.id === Number.parseInt(selected, 10));
    if (u != null) {
      return u.avatar;
    }
    return "";
  }

  getFileUploadUrl() {
    return this.api.backendUrl + "team/" + this.team.id + "/team-logo";
  }

  fileUploadChange(info: NzUploadChangeParam): void {
    if (info.file.status !== "uploading") {
      if (isDevMode()) {
        console.log(info.file, info.fileList);
      }
    }
    if (info.file.status === "done") {
      this.message.success(`Plik ${info.file.name} został zapisany`);
      this.lastupdateTimestamp = new Date().getTime();
      this.loading = true;
      this.loadTeam();
    } else if (info.file.status === "error") {
      this.message.error(`Zapisywanie ${info.file.name} nie powiodło się.`);
    }
  }

  getTeamlogo() {
    return [
      "assets/images/teams/",
      this.team.logo,
      "?",
      this.lastupdateTimestamp,
    ].join("");
  }
}
