<app-loader *ngIf="loading"></app-loader>
<section class="team-section" *ngIf="!loading">
  <div class="app-layout team-container">
    <div class="team-details">
      <div class="team-info inner-content">
        <div class="header">
          <h2 class="subsection-heading">{{ team.name }}</h2>
        </div>
        <img
          class="team-logo"
          [ngClass]="{ 'no-logo': team.logo === 'default.png' }"
          [src]="staticUrl + 'teams/' + team.logo"
        />
        <div class="avatar-change">
          <nz-upload
            [nzAction]="getFileUploadUrl()"
            [nzHeaders]="{ token: userToken$ | async }"
            (nzChange)="fileUploadChange($event)"
          >
            <button [nzType]="'primary'" nz-button nzBlock>
              <i nz-icon nzType="upload"></i>Zmień Logo zespołu
            </button>
          </nz-upload>
        </div>
        <div class="team-stats">
          <div>
            <span class="ragnar-heading">Data założenia</span>
            <h3>{{ team.createdTime | date : "dd-MM-yyyy" }}</h3>
          </div>
          <div>
            <span class="ragnar-heading">Szef zespołu</span>
            <h3>
              <a [routerLink]="['/profile', team.owner.id]"
                >{{ team.owner.firstName }} {{ team.owner.lastName }}</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="team-actions">
        <div class="inner-content">
          <div class="header">
            <h2 class="subsection-heading">Aktywni kierowcy</h2>
            <ng-container *ngIf="userId$ | async; let userId">
              <button
                *ngIf="team.owner.id === userId"
                class="btn btn-outlined-red"
                (click)="addNewDriver()"
              >
                Dodaj nowego kierowcę
              </button>
            </ng-container>
          </div>
          <nz-table
            [nzShowPagination]="false"
            class="active-drivers"
            #activeDrivers
            [nzData]="getOnlyActiveMembers()"
          >
            <thead>
              <tr>
                <th></th>
                <th>Imię i nazwisko</th>
                <th>Od</th>
                <ng-container *ngIf="userId$ | async; let userId">
                  <th *ngIf="team.owner.id === userId">Akcje</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let driver of activeDrivers.data">
                <td class="driver-avatar">
                  <img [src]="staticUrl + 'avatars/' + driver.user.avatar" />
                </td>
                <td>
                  <a [routerLink]="['/profile', driver.user.id]"
                    >{{ driver.user.firstName }} {{ driver.user.lastName }}</a
                  >
                </td>
                <td>{{ driver.dateStart | date : "dd-MM-yyyy" }}</td>
                <ng-container *ngIf="userId$ | async; let userId">
                  <td *ngIf="team.owner.id === userId">
                    <a (click)="disbandMember(driver.id, driver.teamid)"
                      >Usuń z zespołu</a
                    >
                  </td>
                  <td *ngIf="team.owner.id !== userId"></td>
                </ng-container>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <div class="inner-content driver-history">
          <div class="header">
            <h2 class="subsection-heading">Historia kierowców</h2>
          </div>
          <nz-table
            [nzShowPagination]="false"
            class="drivers-history"
            #driverHistory
            [nzData]="team.members"
          >
            <thead>
              <tr>
                <th></th>
                <th>Imię i nazwisko</th>
                <th>Status</th>
                <th>Od</th>
                <th>Do</th>
                <ng-container *ngIf="userId$ | async; let userId">
                  <th *ngIf="team.owner.id === userId">Akcje</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let driver of driverHistory.data">
                <td class="driver-avatar">
                  <img [src]="staticUrl + 'avatars/' + driver.user.avatar" />
                </td>
                <td>
                  <a [routerLink]="['/profile', driver.user.id]"
                    >{{ driver.user.firstName }} {{ driver.user.lastName }}</a
                  >
                </td>
                <td>{{ resolveStatus(driver.proposalStatus) }}</td>
                <td>{{ driver.dateStart | date : "dd-MM-yyyy" }}</td>
                <td>{{ driver.dateStop | date : "dd-MM-yyyy" }}</td>
                <ng-container *ngIf="userId$ | async; let userId">
                  <td
                    *ngIf="
                      team.owner.id === userId && driver.proposalStatus === 1
                    "
                  >
                    <a (click)="disbandMember(driver.id, driver.team.id)"
                      >Usuń z zespołu</a
                    >
                  </td>
                </ng-container>

                <td *ngIf="driver.proposalStatus !== 1"></td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</section>
<nz-modal
  class="invite-driver-modal"
  [(nzVisible)]="addDriverModalVisible"
  nzTitle="Zaproś nowego kierowcę"
  (nzOnCancel)="handleCancelInvite()"
  (nzOnOk)="handleOkInvite($event)"
>
  <app-loader [absolute]="false" *ngIf="userListLoading"></app-loader>
  <form
    *ngIf="!userListLoading"
    nz-form
    [formGroup]="addDriverForm"
    (ngSubmit)="handleOkInvite($event)"
  >
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="name"
        >Kierowca</nz-form-label
      >
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="To pole jest obowiązkowe!"
      >
        <nz-select
          formControlName="userid"
          nzShowSearch
          nzAllowClear
          [nzCustomTemplate]="defaultTemplate"
        >
          <nz-option
            *ngFor="let user of userList"
            [nzLabel]="
              [user.firstName, user.lastName, '- ID:', user.id].join(' ')
            "
            nzValue="{{ user.id }}"
          >
          </nz-option>
        </nz-select>
        <ng-template #defaultTemplate let-selected>
          <img
            class="selected-user-avatar"
            [src]="staticUrl + 'avatars/' + getUserAvatar(selected.nzValue)"
          />
          {{ selected.nzLabel }}
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <div *nzModalFooter>
      <nz-form-item>
        <div nz-col [nzSm]="6" [nzXs]="24"></div>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <button nz-button nzType="default" (click)="handleCancelInvite()">
            Anuluj
          </button>
          <button
            nz-button
            class="login-form-button"
            [nzType]="'primary'"
            (click)="handleOkInvite($event)"
          >
            Wyślij
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</nz-modal>
