<section class="app-layout">
  <div nz-row class="footer-bottom-content nz-row" nzJustify="space-evenly">
    <div nz-col nzXs="24" [nzXl]="{ span: 3, offset: 0 }" class="mt-5">
      <h3 class="subsection-heading text-center  pb-2">Sponsor Główny</h3>
      <div class="logos d-flex justify-content-center">
        <img alt="Porsche" [src]="staticUrl + 'porscheesports/2024/logos/porsche.webp'" />
      </div>
    </div>
    <div nz-col nzXs="24" [nzXl]="{ span: 3, offset: 0 }" class="mt-5">
      <h3 class="subsection-heading text-center  pb-2">Organizator wydarzenia</h3>
      <div class="logos d-flex justify-content-center">
        <img alt="Ragnar Simulator" [src]="staticUrl + 'porscheesports/2024/logos/ragnar.webp'" />
      </div>
    </div>
    <div nz-col nzXs="24" [nzXl]="{ span: 3, offset: 0 }" class="mt-5">
      <h3 class="subsection-heading text-center pb-2">Partner techniczny</h3>
      <div class="logos d-flex justify-content-center">
        <img alt="iRacing" [src]="staticUrl + 'porscheesports/2024/logos/iracing.webp'" />
      </div>
    </div>
    <div nz-col nzXs="24" nzXl="9" class="mt-5">
      <div class="buttons d-flex justify-content-center justify-content-md-end mt-2 mt-md-0">
        <a href="https://www.facebook.com/porscheesportspl/" title="Facebook" class="contact-btn facebook">
          <app-icon-facebook></app-icon-facebook>
        </a>
        <a href="https://www.youtube.com/@PorschePolskaPL" title="YouTube" class="contact-btn youtube">
          <app-icon-youtube></app-icon-youtube>
        </a>
        <a href="https://www.instagram.com/porscheesports_pl" title="Instagram" class="contact-btn instagram">
          <app-icon-instagram></app-icon-instagram>
        </a>
        <a href="https://discord.gg/WfN8SJUjD3" title="Discord" class="contact-btn discord">
          <app-icon-discord></app-icon-discord>
        </a>
      </div>
    </div>
  </div>
  <div nz-row nzXs="24" nzXl="3" nzJustify="center">
    <div nz-col class="owner">
      Porsche {{ today | date : "yyyy" }}
    </div>
  </div>
  <!--</ng-template>-->
</section>