import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TeamMembershipStatusService {

  statusTable: string[] = [
    'Propozycja dołączenia',
    'Dołączono',
    'Odrzucono propozycję',
    'Opuszczono zespół',
    'Usunięto z zespołu przez szefa',
    'Usunięto zespół'
  ];

  constructor() { }

  getStatus(id: number): string {
    if (id < this.statusTable.length) {
      return this.statusTable[id];
    }
    return '';
  }
}
